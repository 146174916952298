import React from 'react'
import AcfComponents from './AcfComponents'
export const FlexibleLayout = ({layout, location, pageContext}) => {

  if(!layout?.__typename) {
    <section className="page missing" id={layout?.id}>
      <div>Some ACF components is missing which is not passed as layout. This means something in the schema is broken for this component. Check build console errors.</div>
    </section>
  }

  const indexOfLastUnderscore = layout?.__typename.lastIndexOf('_')
  const type = layout.__typename.substring(indexOfLastUnderscore + 1)
  const ComponentName = AcfComponents[type]

  if(!ComponentName) {
    return (
      <section className="page missing">
        <div>The ACF component <strong>"{type}"</strong> is missing.</div>
      </section>
    )
  }

  return(
    <ComponentName data={layout} location={location} pageContext={pageContext} />
  )
}

