import React from "react"
import { LocationCard } from "./LocationCard"

export const LocationGroup = ({ allLocations = [] }) => {
  return (
    <section className="location-group">
      <div className="row">
        {allLocations.map((location, index) => {
          const totalCount = location.wpChildren.nodes.length
          return (
            <LocationCard
              className="col-1-of-3"
              totalCount={totalCount}
              {...location}
              key={index}
            />
          )
        })}
      </div>
    </section>
  )
}
