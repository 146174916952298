import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import React from "react"
import { FaMap, FaMapSigns, FaPhone, FaRegEnvelope } from "react-icons/fa"
import { formatPhone } from "../utils/formatPhone"
import { normalizePath } from "../utils/normalizePath"
import Button from "./Button"
export const LocationCard = (props) => {
  const {
    className,
    totalCount,
    parent,
    title,
    featuredImage,
    locationInfo,
    uri,
  } = props
  const isParent = !parent
  const countText =
    totalCount > 1 || totalCount === 0
      ? `${totalCount} Locations`
      : `${totalCount} Location`
  return (
    <article className={`location-card ${className ? className : ""}`}>
      <header className="location-card__header">
        {props?.featuredImage && (
          <GatsbyImage
            image={featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
            alt={featuredImage?.node?.altText} />
        )}
        {isParent ? (
          <h3 className="location-card__title">{parse(title)}</h3>
        ) : (
          <h3 className="location-card__title">
            {locationInfo?.name + " "}
            <span className="location-card__title--span">
              {parse(`${locationInfo?.city}, ${locationInfo?.state}`)}
            </span>
          </h3>
        )}
      </header>
      <section className="location-card__body">
        {isParent ? (
          <p className="location-card__card-count">{countText}</p>
        ) : (
          <div className="location-card__contact">
            {locationInfo?.contact?.phone && (
              <div className="location-card__details">
                <FaPhone className="location-card__details--icon" />
                <a
                  href={`tel:+1${locationInfo?.contact?.phone}`}
                  className="location-card__details--phone"
                >
                  {formatPhone(locationInfo?.contact?.phone)}
                </a>
              </div>
            )}
            <div className="location-card__details">
              <FaMap className="location-card__details--icon" />
              <p className="location-card__details--address">
                {locationInfo?.streetAddress}
                <br />
                {locationInfo?.city}, {locationInfo?.state}{" "}
                {locationInfo?.zipcode}
              </p>
            </div>
            <div className="location-card__details">
              <FaRegEnvelope className="location-card__details--icon" />
              <a
                href={`mailto:${locationInfo?.contact?.email}`}
                className="location-card__details--email"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                {parse(locationInfo?.contact?.email)}
              </a>
            </div>
          </div>
        )}
      </section>
      <div className="location-card__footer">
        <Button buttonStyle="primary" small to={normalizePath(uri)}>
          <FaMapSigns />
          {isParent ? "View Locations": "Visit Location"}
        </Button>
      </div>
    </article>
  );
}
