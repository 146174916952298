import { graphql } from "gatsby"
import React from "react"
import { FlexibleLayout } from "../../components/FlexibleLayout"
import Layout from "../../components/Layout"
import { LocationGroup } from "../../components/LocationGroup"
import { MetalColors } from "../../components/MetalColors"
import SEO from "../../components/SEO"

const Page = ({ data, pageContext, location }) => {
  const {
    page: {isFrontPage, title, metaDescription: {description}, pageLayout}
  } = data
  const pageClassName = location.pathname.replace(/^\/+/, "")


  return (
    <Layout isFrontPage={isFrontPage} className={pageClassName}>
      <SEO title={title} description={description} />
      {pageLayout?.pageSections && pageLayout?.pageSections.map((layout, index) => {

        return (
          <FlexibleLayout key={index} layout={layout} location={location} pageContext={pageContext} />
        )
      })}
      {pageContext?.metalColors && <MetalColors {...pageContext?.metalColors}  />}
      {pageContext?.locations && <LocationGroup {...pageContext?.locations}  />}
    </Layout>
  )
}

export const PageQuery = graphql`
  query pageTemplateQuery($id: String!) {
    page: wpPage(id: {eq: $id}) {
      ...PageContent
    }
  }
`

export default Page
