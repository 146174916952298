import React from "react"

export const MetalColors = ({
  colors: {
    0: {
      acfProductOptions: {
        productOptions: {
          0: { colors },
        },
      },
    },
  },
}) => {
  return (
    <section className="metal-colors">
      <p className="metal-colors__disclaimer">
        Due to differing computer screens, colors pictured on web may not be
        100% accurate. Please see local dealer for exact colors.
      </p>
      {colors.map(({ name, value }) => (
        <div className="metal-colors__color-block" key={name}>
          <div
            className="metal-colors__color-block__color"
            style={{ backgroundColor: value }}
          />
          <p className="metal-colors__color-block__name">{name}</p>
        </div>
      ))}
    </section>
  )
}
