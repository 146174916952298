import loadable from '@loadable/component'

const AcfComponents = {
  HeroSection: loadable(() => import('./pagebuilder-parts/HeroSection')),
  NewHero: loadable(() => import('./pagebuilder-parts/NewHero')),
  BasicHero: loadable(() => import('./pagebuilder-parts/BasicHero')),
  CallToAction: loadable(() => import('./pagebuilder-parts/CallToAction')),
  ListSection: loadable(() => import('./pagebuilder-parts/ListSection')),
  ProductCards: loadable(() => import('./pagebuilder-parts/ProductCards')),
  PageTitleSection: loadable(() => import('./pagebuilder-parts/PageTitleSection')),
  BodyContent: loadable(() => import('./pagebuilder-parts/BodyContent')),
  Heading2: loadable(() => import('./pagebuilder-parts/Heading2')),
  Heading3: loadable(() => import('./pagebuilder-parts/Heading3')),
  FaqDropdowns: loadable(() => import('./pagebuilder-parts/FaqDropdowns')),
}

export default AcfComponents